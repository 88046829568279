<header class="top-bar flex-row justify-center" role="banner">
    <button class="close-icon cursor-pointer" (click)="onCancel()" aria-label="Close">
        <mat-icon>close</mat-icon>
    </button>
</header>
<section class="confirmation-container">
    <p class="option-text" [innerHTML]="confirmationMsg"></p>
</section>
<footer class="submit-buttons flex-col space-between align-center gap-10">
    <button (click)="onConfirm()" class="apply fill-button" mat-raised-button aria-label="Click here to confirm">
        <span class="va-middle" i18n>{{ confirmButtonText }}</span>
    </button>
    @if(!hideCancelButton) {
        <button class="cancel outline-button" mat-raised-button (click)="onCancel()" aria-label="Click here to close">
            <span class="va-middle" i18n>{{ cancelButtonText }}</span>
        </button>
    }
    @if(shareEventId) {
        <button class="cancel outline-button" mat-raised-button (click)="copyLink()" aria-label="Click here to copy link">
            <span class="va-middle" i18n>Copy link</span>
        </button>
    }
</footer>