import { NgIf } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { Message } from 'src/app/services/hobbyts.service';
import { generateEventLink } from 'src/app/utils/profile.utils';
import { StorageService } from 'src/app/services/storage.service';

interface ConfirmationModalData {
  confirmationMsg: string;
  confirmButtonText: string;
  cancelButtonText: string;
  hideCancelButton: boolean;
  shareEventId: string;
  createEventMessage: Message;
}

@Component({
  selector: 'hb-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  standalone: true,
  styleUrls: ['./confirmation-modal.component.scss'],
  imports: [NgIf, MatIconModule, MatButtonModule]
})
export class ConfirmationModalComponent implements OnInit {
  public confirmationMsg: string;
  public confirmButtonText: string;
  public cancelButtonText: string;
  public hideCancelButton: boolean;
  public shareEventId: string;
  public createEventMessage: Message;
  public messageId: string | undefined | null;
  public chatName: string;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalData,
    private snackBar: MatSnackBar,
    private clipboard: Clipboard,
    private storageService: StorageService
  ) {}

  public ngOnInit(): void {
    this.confirmationMsg = this.data.confirmationMsg;
    this.confirmButtonText = this.data.confirmButtonText || $localize`Confirm`;
    this.cancelButtonText = this.data.cancelButtonText || $localize`Cancel`;
    this.hideCancelButton = this.data.hideCancelButton || false;
    this.shareEventId = this.data.shareEventId || "";
    this.createEventMessage = this.data.createEventMessage || null;
    this.messageId = this.createEventMessage ? this.createEventMessage.id : '';
    this.chatName = this.createEventMessage ? this.createEventMessage.chatName || `c/${this.createEventMessage.chatId!.slice(4)}` : ''
  }

  public copyLink(): void {
    this.clipboard.copy(generateEventLink(this.storageService, this.shareEventId));
    this.snackBar.open($localize`Link copied`, $localize`:@@close:Close`, getSnackBarConfig());
  }

  public onConfirm(): void {
    this.dialogRef.close(true);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }
}
